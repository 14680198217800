<template>
  <b-card-code no-body title="Matched List">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
      <div class="row">

        <div class="col-3">
          <select class="form-control form-control-sm" v-model="filter.commodity_slug">
            <option selected value="">-- All Asset --</option>
            <option v-for="commodity in commodities" v-bind:key="commodity.slug" v-bind:value="commodity.slug">{{ commodity.code }} ({{ commodity.name }})</option>
          </select>
        </div>

        <div class="col-3">
          <flat-pickr
            v-model="filter.date"
            class="form-control form-control-sm"
            placeholder="Select date..."
            :config="{dateFormat: 'Y-m-d'}"
          />
        </div>

        <div class="col-3"></div>

        <div class="col-3 text-right">
          <input type="text" v-model="filter.search" placeholder="Search..." class="form-control form-control-sm float-right" style="width: 200px;">
        </div>

      </div>
    </div>


    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th>
              ID
            </th>
            <th>
              Account
            </th>
            <th>
              Asset
            </th>
            <th>
              Side
            </th>
            <th>
              Order Price
            </th>
            <th style="cursor: pointer;" @click="sortBy('price')" >
              Match Price
              <span v-if="filter.sort_by == 'price'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th style="cursor: pointer;" @click="sortBy('lot')">
              Lot
              <span v-if="filter.sort_by == 'lot'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th>
              Order ID
            </th>
            <th style="cursor: pointer;" @click="sortBy('created_at')">
              Fill Time
              <span v-if="filter.sort_by == 'created_at'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="8" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <template v-else v-for="matched in result.data">
            <tr v-bind:key="matched.id + 'sell'">
              <td>{{ matched.id }}</td>
              <td>
                <router-link :to="{ name: 'users.show', params: { id: matched.ask_transaction.user.id } }">
                  {{ matched.ask_transaction.user.name }} 
                </router-link>
              </td>
              <td>
                {{ matched.auction.commodity.code }}
              </td>
              <td>Sell</td>
              <td>
                {{ matched.ask_transaction.price }}
              </td>
              <td>
                {{ matched.price }}
              </td>
              <td>
                {{ matched.lot }} lot
              </td>
              <td>
                <router-link :to="{ name: 'auctions.transactions.show', params: { uuid: matched.ask_transaction.uuid } }">
                  {{ matched.ask_transaction.id }}
                </router-link>
              </td>
              <td>
                {{ matched.time_formatted }}
              </td>
            </tr>
            <tr v-bind:key="matched.id + 'buy'">
              <td>{{ matched.id }}</td>
                <td>
                  <router-link :to="{ name: 'users.show', params: { id: matched.bid_transaction.user.id } }">
                    {{ matched.bid_transaction.user.name }} 
                  </router-link>
                </td>
                <td>
                  {{ matched.auction.commodity.code }}
                </td>
                <td>Buy</td>
                <td>
                  {{ matched.bid_transaction.price }}
                </td>
                <td>
                  {{ matched.price }}
                </td>
                <td>
                  {{ matched.lot }} lot
                </td>
                <td>
                  <router-link :to="{ name: 'auctions.transactions.show', params: { uuid: matched.bid_transaction.uuid } }">
                    {{ matched.bid_transaction.id }}
                  </router-link>
                </td>
                <td>
                  {{ matched.time_formatted }}
                </td>
            </tr>
          </template>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="8" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }} match</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { EyeIcon } from 'vue-feather-icons'
import { checkPermission } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'

export default {
  title () {
    return `Matched Auction Transaction`
  },
  setup() {
    return {
      checkPermission,
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
    EyeIcon,
    flatPickr,
    BSpinner
  },
  watch: {
    filter: {
      handler: _.debounce(function() {                                                                                                                                                              
        this.getData();                                                                                                                                                                        
      }, 200),
      deep: true
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        search: '',
        commodity_slug: '',
        sort_by: null,
        sort_type: null,
      },
      isLoading: false,
      commodities: [],
    }
  },
  created() {
    this.getData();
    this.loadFilterData();
  },
  mounted() {
    var context = this
    this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      if (payload.type == 'auction-transaction-match') {
        context.getData(context.currentPage || 1);  
      }
    });
  },
  methods: {
    loadFilterData() {
      this.$http.get('/public/commodities/')
      .then(response => {
        this.commodities = response.data.data
      })
    },
    getData(page = 1) {

      // this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page
      queryParams.has_match = 1

      this.$http.get('/admin/matched-list', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        // this.isLoading = false;
      })

    },
    goToDetailAuction(uuid) {
        this.$router.push({ name: 'auctions.show', params: { uuid: uuid } })
    },
    goToDetailTransaction(uuid) {
        this.$router.push({ name: 'auctions.transactions.show', params: { uuid: uuid } })
    },
    sortBy(by) {
      if (this.filter.sort_by != by) {
        this.filter.sort_type = null
      }
      this.filter.sort_by = by
      
      if (this.filter.sort_type == null) {
        this.filter.sort_type = 'asc';
      } else if (this.filter.sort_type == 'asc') {
        this.filter.sort_type = 'desc';
      } else {
        this.filter.sort_type = null;
      }

      this.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>